import React from "react";
import serviceS1 from "@/images/s1.jpg";
import serviceS2 from "@/images/s2.jpg";
import uiux1 from "@/images/uiux1.jpg";
import uiux3 from "@/images/uiux3.jpg";
const ServiceDetailsContentThree = () => {
  return (
    <div className="serviceArea">
      <img src={uiux1} alt="" />
      <h2>Building Memorable User Experiences </h2>
      <p>
        Design is not just art or something that is visually appealing, 
        but a strategic way of how to solve a problem, a planned approach 
        taken up for a challenge. This is what great and innovative designs 
        achieve and this is why they work. One of the key components of any 
        technology solution is its design, and the design team at Kloudxel 
        uses thorough subject-matter research and analysis to create innovative 
        yet sustainable and feasible design solutions for you. 
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={uiux3} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>UI/UX Services</h3>
          <p>
            We offer the following services:
          </p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Research And Storyboarding
            </li>
            <li>
              <i className="fa fa-check-square"></i>Comprehensive UI designing
            </li>
            <li>
              <i className="fa fa-check-square"></i>Comprehensive UX designing
            </li>
            <li>
              <i className="fa fa-check-square"></i>Responsive Designs
            </li>
            <li>
              <i className="fa fa-check-square"></i>Mockups, Wireframes, and Prototypes 
            </li>
          </ul>
        </div>
      </div>
      <p>
        User experience and user interface are the crucial design methodologies 
        behind a digital application or solution. Your brand value and 
        customers’ positive response depend on how inspiring, captivating, and 
        user-friendly the design is for them. This is why, Kloudxel ensures with 
        its research-based requirement gathering, and following the trends and 
        guidelines set in the industry, to create UI/UX designs that are meaningful 
        and engaging, and breathe life into your digital applications. 
      </p>
    </div>
  );
};

export default ServiceDetailsContentThree;
